module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"lgv-drivers-choice","defaultLang":"en-gb","path":"/preview","previews":true,"pages":[{"type":"Page","match":"/","path":"/","component":"/opt/build/repo/src/templates/page.jsx"},{"type":"Page","match":"/:uid","path":"/","component":"/opt/build/repo/src/templates/page.jsx"}],"sharpKeys":[{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LGV Drivers Choice","short_name":"LGV Drivers Choice","start_url":"/","background_color":"#4299e1","theme_color":"#4299e1","display":"minimal-ui","icon":"src/images/icon_lgv-drivers-choice.jpg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"2a2058b50876c6fc03d65860e43161a6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-174927783-1","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
